import { t } from "translations"

import Typography from "@mui/material/Typography"

import useUserCategory from "Components/UserCategoryModal/useUserCategory"

export default function AdTitle() {
    const { userCategory } = useUserCategory()

    if (userCategory === "professional")
        return (
            <Typography
                variant="body2"
                component="span">
                {t.ads.professionalAdTitle}
            </Typography>
        )
    else
        return (
            <Typography
                variant="body2"
                component="span">
                {t.ads.defaultAdTitle}
            </Typography>
        )
}
