import { PortableTextTypeComponentProps } from "@portabletext/react"
import { RefObject, useEffect, useRef } from "react"

export default function Survey(props: PortableTextTypeComponentProps<any>) {
    const { src } = props.value

    const scriptWithUrl = useRef<HTMLDivElement>(null)
    useScriptUrl(
        `${src}?__output=embedjs&__ref='${escape(getDocumnetLocationHref())}`,
        scriptWithUrl
    )

    function getDocumnetLocationHref() {
        if (typeof window !== "undefined") return window.location.href
        return ""
    }

    return (
        <>
            <div ref={scriptWithUrl} />

            <iframe
                src={src}
                title="Survey"
                width={"100%"}
                height={500}
            />
        </>
    )
}

const useScriptUrl = (url: string, ref: RefObject<HTMLDivElement>) => {
    useEffect(() => {
        const script = document.createElement("script")

        script.src = url
        script.async = true

        if (ref.current) ref.current.appendChild(script)

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            if (ref.current) ref.current.removeChild(script)
        }
    }, [url, ref])
}
