import * as React from "react"
import { PortableTextTypeComponentProps } from "@portabletext/react"
import components from "calculators/dynamicComponents"
import { CalculatorBaseProps } from "calculators/shared"
import { useEffect, useState } from "react"
import Skeleton from "@mui/material/Skeleton"

type Components = typeof components
type CalculatorId = keyof Components

type CalculatorProps = CalculatorBaseProps & {
    id: CalculatorId
}

type CalculatorSerializerProps = PortableTextTypeComponentProps<CalculatorProps>

export function Calculator(props: CalculatorProps) {
    const [StateCalculator, setCalculator] = useState<Components[CalculatorId]>()

    useEffect(() => {
        const getCalculator = async () => {
            const CalculatorComponent = components[props.id]
            if (CalculatorComponent) {
                setCalculator(() => CalculatorComponent)
                return
            }
        }
        getCalculator()
    }, [props.id])

    if (StateCalculator)
        return <StateCalculator {...props} />
    return <Skeleton />
}

export default function CalculatorSerializer({ value }: CalculatorSerializerProps) {
    return <Calculator {...value} />
}