export enum CONTENT_TYPE {
    START = "content-page-start",
    TOPIC = "content-page-topic",
    LIST = "content-page-list",
    SYSTEM = "content-page-system",

    CONTENTMARKETING_VIDEO_ARTICLE = "content-page-article-public-publicvideo-cmpublicvideo",
    CONTENTMARKETING_ARTICLE = "content-page-article-public-cmpublic",
    CONTENTMARKETING_ADVERTISER = "content-page-article-public-publiccontainer-cmpublicadvertiser",
    CARD_CONTENT = "content-block-card-contentcard",
    CARD_ARTICLES = "content-block-card-articlescard",
    CARD_CONTAINER = "content-block-card-containercard",
    ARTICLE = "content-page-article"
}

/**
 * Gets the content type from the types array
 * @param types types array
 * @returns the content type
 */
export function getContentType(types?: string[]): CONTENT_TYPE {
    if (!types) return CONTENT_TYPE.ARTICLE

    const type = types.join("-").toLowerCase()

    if (type.startsWith(CONTENT_TYPE.START)) return CONTENT_TYPE.START

    if (type.startsWith(CONTENT_TYPE.TOPIC)) return CONTENT_TYPE.TOPIC

    if (type.startsWith(CONTENT_TYPE.LIST)) return CONTENT_TYPE.LIST

    if (
        type.startsWith(CONTENT_TYPE.CONTENTMARKETING_ARTICLE) ||
        type.startsWith(CONTENT_TYPE.CONTENTMARKETING_VIDEO_ARTICLE) ||
        type.startsWith(CONTENT_TYPE.CONTENTMARKETING_ADVERTISER)
    )
        return CONTENT_TYPE.CONTENTMARKETING_ARTICLE

    // Cards
    if (type.startsWith(CONTENT_TYPE.CARD_CONTENT))
        return CONTENT_TYPE.CARD_CONTENT

    if (type.startsWith(CONTENT_TYPE.CARD_ARTICLES))
        return CONTENT_TYPE.CARD_ARTICLES

    if (type.startsWith(CONTENT_TYPE.CARD_CONTAINER))
        return CONTENT_TYPE.CARD_CONTAINER

    if (type.startsWith(CONTENT_TYPE.SYSTEM)) return CONTENT_TYPE.SYSTEM

    return CONTENT_TYPE.ARTICLE
}
