/**
 * Get the value from an object.
 * @param object Object to get property from
 * @param key Property in object
 * @returns Object value
 */
export default function getValueFromObject<T, K extends keyof T>(
    object: T,
    key: K
): T[K] {
    const value = object[key]
    return value
}
