/* eslint-disable react-hooks/exhaustive-deps */
import { RefObject, createContext, useContext, useEffect, useRef } from "react"

import { IPage } from "data/contentData/interfaces/mongodb/IPage"
import { CONTENT_TYPE, getContentType } from "lib/getContentType"

import useGetGAMValues, { IGamValues } from "./useGetGAMValues"

export interface IParagraph {
    id: number
    length: number
    content: string
    acc?: number
    tempAcc?: number
    showAd?: boolean
}

interface IAdContext {
    showAds: boolean
}

const AdContext = createContext<IAdContext>({ showAds: true })

export function AdContextProvider({
    page,
    children
}: {
    page: IPage
    children: JSX.Element | JSX.Element[]
}) {
    const contentType = getContentType(page.types)

    let showAds = true
    if (contentType === CONTENT_TYPE.SYSTEM) showAds = false
    if (page.noAdverts) showAds = false

    const value: IAdContext = {
        showAds
    }

    return (
        <AdContext.Provider value={value}>
            {value.showAds && <AdScripts page={page} />}
            {children}
        </AdContext.Provider>
    )
}

/**
 * throws error if AdContext can not be found.
 * @returns either context or error informing useAdContext must be used in
 * AdContextProvider
 */
export function useAdContext() {
    const context = useContext(AdContext)
    if (!context)
        throw new Error("useAdContext must be used within AdContextProvider")

    return context
}

function AdScripts({ page }: { page: IPage }) {
    const gamValues = useGetGAMValues(page)

    const scriptBlockGamValues = useRef<HTMLDivElement>(null)
    useScriptGamValues(gamValues, scriptBlockGamValues)

    const scriptBlockLoadAds = useRef<HTMLDivElement>(null)
    useScriptLoadAds(gamValues, scriptBlockLoadAds)

    return (
        <>
            <div ref={scriptBlockGamValues} />
            <div ref={scriptBlockLoadAds} />
        </>
    )
}

const useScriptGamValues = (
    gamValues: IGamValues,
    ref: RefObject<HTMLDivElement>
) => {
    useEffect(() => {
        if (!gamValues.site) return

        const script = document.createElement("script")

        script.async = true
        script.text = `
        window.googletag = window.googletag || { cmd: [] };
            googletag.cmd.push(function() {
            googletag.pubads().setTargeting("env", "${gamValues.env}");
            googletag.pubads().setTargeting("site", "${gamValues.site}");
            googletag.pubads().setTargeting("section", "${gamValues.section}");
            googletag.pubads().setTargeting("context", "Site${gamValues.context}");
            googletag.pubads().setTargeting("tags", "${gamValues.tags}");
        });
        `
        if (ref.current) ref.current.appendChild(script)

        return () => {
            if (ref.current) ref.current.removeChild(script)
        }
    }, [
        gamValues.context,
        gamValues.env,
        gamValues.section,
        gamValues.site,
        gamValues.tags,
        ref
    ])
}

const useScriptLoadAds = (
    gamValues: IGamValues,
    ref: RefObject<HTMLDivElement>
) => {
    useEffect(() => {
        if (!gamValues.site) return

        const script = document.createElement("script")

        script.async = true
        script.text = `
        function getPath() {
            if (window.location.pathname == "/pro")
                return "/";

            return window.location.pathname + window.location.search;
        }

        window.loadAds = () => {
            if (window.loadedAds && window.loadedAds.path == getPath() && window.loadedAds.site == "${gamValues.site}") {
                return;
            }

            try {
                window.relevantDigital.destroySlots();
            } catch { }
            
            window.relevantDigital = window.relevantDigital || {};
            relevantDigital.cmd = relevantDigital.cmd || [];
            relevantDigital.cmd.push(function() {
                relevantDigital.loadPrebid({
                    configId: (innerWidth >= 992) ? '${process.env.NEXT_PUBLIC_GAM_CONFIG_ID}' : '${process.env.NEXT_PUBLIC_GAM_CONFIG_ID_MOBILE}',
                    manageAdserver: true,
                    collapseBeforeAdFetch: false,
                    allowedDivIds: null, // set to an array to only load certain <div>s, example - ["divId1", "divId2"]
                    noSlotReload: true, // set true to only load ad slots that have never been loaded
                    googletagCalls: {
                        defineSlot: function (adUnitPath, size, div) {
                            var elm = document.getElementById(div);
                            var format = elm && elm.getAttribute('data-ad-format');

                            // Clean any MCM-child id on ad units
                            var path = adUnitPath.indexOf(this.auction.globalAdserverSettings.networkCode) >= 0
                                ? adUnitPath : Utils.cleanMcmPart(adUnitPath);

                            if (format === 'anchor') {
                                return googletag.defineOutOfPageSlot(path, googletag.enums.OutOfPageFormat.BOTTOM_ANCHOR);
                            }
                            return googletag.defineSlot(path, size, div); // Do the actual call
                        },
                    },
                });
            });

            window.loadedAds = {
                path: getPath(),
                site: "${gamValues.site}"
            };
        };
        loadAds();
        `

        if (ref.current) ref.current.appendChild(script)

        return () => {
            if (ref.current) ref.current.removeChild(script)
        }
    }, [
        gamValues.context,
        gamValues.env,
        gamValues.section,
        gamValues.site,
        gamValues.tags,
        ref
    ])
}
