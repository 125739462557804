import { PortableTextTypeComponentProps } from "@portabletext/react"

import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import ImageComponent from "Components/Image/Image"
import { useGalleryContext } from "Components/Layout/PageComponent/GalleryProvider"

type ICmsImageAlign = "none" | "pull-left" | "pull-right"
type ICmsImageSize = "small" | "medium" | "large"

interface IImageProps {
    src: string
    alt?: string
    title?: string
    caption?: string
    width?: number
    height?: number
    noZoom?: boolean
    index?: number
    align?: ICmsImageAlign
    size?: ICmsImageSize
}

export default function Image(
    props: PortableTextTypeComponentProps<IImageProps>
) {
    const galleryContext = useGalleryContext()
    const { src, alt, width, height, title, index, caption, align, size } =
        props.value

    const imageToRender =
        index || index === 0 ? (
            <StyledGalleryImage
                onClick={() => galleryContext.setGalleryIndexSelected(index)}
                src={src}
                alt={alt}
                title={title}
                width={width}
                height={height}
                loading={"lazy"}
            />
        ) : (
            <StyledImage
                src={src}
                alt={alt}
                title={title}
                width={width}
                height={height}
                loading={"lazy"}
            />
        )

    if (align && align !== "none")
        return (
            <StyledImageWrapper
                align={align}
                size={size}>
                {imageToRender}
                {caption && (
                    <StyledTypography variant="caption">
                        {caption}
                    </StyledTypography>
                )}
            </StyledImageWrapper>
        )

    return (
        <>
            {imageToRender}
            {caption && (
                <StyledTypography variant="caption">{caption}</StyledTypography>
            )}
        </>
    )
}

function getAlignFloat(align: ICmsImageAlign | undefined) {
    if (align === "pull-left") return "left"
    if (align === "pull-right") return "right"

    return "none"
}

function getAlignPadding(align: ICmsImageAlign | undefined, spacing: string) {
    if (align === "pull-left") return { paddingRight: spacing }
    if (align === "pull-right") return { paddingLeft: spacing }

    return {}
}

function getMaxSize(
    size: ICmsImageSize | undefined | null,
    align?: ICmsImageAlign | undefined
) {
    if (size === "small") return "33%"
    if (size === "medium") return "50%"
    if (size === null && (align === "pull-left" || align === "pull-right"))
        return "50%"

    return "100%"
}

const centerImage = { display: "block", margin: "auto" }

const StyledGalleryImage = styled(ImageComponent)(({ theme }) => ({
    paddingBottom: theme.spacing(2),
    cursor: "pointer",
    ...centerImage
}))

const StyledImage = styled(ImageComponent)(() => ({
    ...centerImage
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: "block",
    textAlign: "center"
}))

const StyledImageWrapper = styled("div")<{
    align: ICmsImageAlign | undefined
    size: ICmsImageSize | undefined
}>(({ align, size, theme }) => ({
    display: "block",
    ...(size !== "large" && {
        maxWidth: getMaxSize(size)
    }),
    [theme.breakpoints.up("sm")]: {
        ...{ float: getAlignFloat(align) },
        ...getAlignPadding(align, theme.spacing(1)),
        ...(size !== "large" && {
            maxWidth: getMaxSize(size, align)
        })
    }
}))
