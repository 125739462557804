import { styled } from "@mui/material/styles"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import { createContext, useContext, useState, Dispatch } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
import "swiper/css/keyboard"
import { A11y, Keyboard, Navigation, Pagination, Scrollbar } from "swiper"
import Button from "@mui/material/Button"
import { IImage } from "data/contentData/interfaces/mongodb/IImage"
import ContentImage from "Components/Image/ContentImage"
import { t } from "translations"
import { IArticle } from "data/contentData/interfaces/mongodb/IArticle"
import { IPage } from "data/contentData/interfaces/mongodb/IPage"
import Icon from "Components/Icon/Icon"
import getValueFromObject from "lib/getValueFromObject"

const GalleryContext = createContext<{
    setGalleryIndexSelected: Dispatch<React.SetStateAction<number | undefined>>
}>({
    setGalleryIndexSelected: () => void 0
})

export function GalleryProvider({
    page,
    children
}: {
    page: IPage
    children: JSX.Element | JSX.Element[]
}) {
    const gallery = getValueFromObject(page as IArticle, "gallery") ?? []

    const [galleryIndexSelected, setGalleryIndexSelected] = useState<
        number | undefined
    >()

    return (
        <GalleryContext.Provider value={{ setGalleryIndexSelected }}>
            {children}
            {gallery && galleryIndexSelected !== undefined && (
                <Gallery
                    gallery={gallery}
                    galleryIndexSelected={galleryIndexSelected}
                />
            )}
        </GalleryContext.Provider>
    )
}

/**
 * function that simply returns the gallery context
 * @returns the gallery context
 */
export function useGalleryContext() {
    return useContext(GalleryContext)
}

function Gallery({
    gallery,
    galleryIndexSelected
}: {
    gallery: IImage[]
    galleryIndexSelected: number | undefined
}) {
    const galleryContext = useGalleryContext()

    if (!galleryIndexSelected && galleryIndexSelected !== 0) return null

    return (
        <Modal
            open={true}
            onClose={() => galleryContext.setGalleryIndexSelected(undefined)}
            aria-labelledby={t.gallery.title}
            aria-describedby={t.gallery.description}>
            <StyledBox>
                <StyledSwiper
                    modules={[
                        Navigation,
                        Pagination,
                        Scrollbar,
                        A11y,
                        Keyboard
                    ]}
                    spaceBetween={50}
                    slidesPerView={1}
                    navigation
                    initialSlide={galleryIndexSelected}
                    pagination={{ clickable: true }}
                    scrollbar={{ draggable: true }}
                    a11y={{
                        prevSlideMessage: t.gallery.previousSlide,
                        nextSlideMessage: t.gallery.nextSlide
                    }}
                    keyboard={{
                        enabled: true,
                        onlyInViewport: false
                    }}>
                    {gallery.map(image => (
                        <SwiperSlide key={image.src}>
                            <StyledCloseButton
                                onClick={() =>
                                    galleryContext.setGalleryIndexSelected(
                                        undefined
                                    )
                                }>
                                <Icon name="close" />
                            </StyledCloseButton>
                            <StyledContentImage image={image} />
                            <StyledParagraph>{image.caption}</StyledParagraph>
                        </SwiperSlide>
                    ))}
                </StyledSwiper>
            </StyledBox>
        </Modal>
    )
}

const StyledCloseButton = styled(Button)(() => ({
    float: "right"
}))

const StyledParagraph = styled("p")(() => ({
    textAlign: "center",
    margin: 0,
    height: 100
}))

const StyledContentImage = styled(ContentImage)(({ theme }) => ({
    maxWidth: `calc(100vw - ${theme.spacing(5)})`,
    maxHeight: `calc(100vh - 100px - 64px - ${theme.spacing(5)})`,
    objectFit: "contain"
}))

const StyledSwiper = styled(Swiper)(({ theme }) => ({
    "--swiper-pagination-color": theme.palette.primary.main,
    "--swiper-navigation-color": theme.palette.primary.main
}))

const StyledBox = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: `calc(100vw - ${theme.spacing(2)})`,
    height: `calc(100vh - ${theme.spacing(2)})`,
    backgroundColor: theme.palette.common.white,
    border: `${theme.spacing(0.5)} solid ${theme.palette.primary.main}`,
    padding: theme.spacing(2)
}))
