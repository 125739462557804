import match from "autosuggest-highlight/match"
import parse from "autosuggest-highlight/parse"
import getConfig from "next/config"
import { useRouter } from "next/router"
import { SetStateAction, SyntheticEvent, useEffect, useState } from "react"
import { t } from "translations"

import MUIAutocomplete from "@mui/material/Autocomplete"
import CircularProgress from "@mui/material/CircularProgress"
import IconButton from "@mui/material/IconButton"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { styled, useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

import useDebounce from "@nhi/hooks/useDebounce"
import { normalizeQuery } from "@nhi/utils"

import Icon from "Components/Icon/Icon"

import { ISearchHeaderItem } from "./ISearchHeaderItem"
import useHeaderSearchData from "./useHeaderSearchData"

export default function HeaderSearch() {
    const [input, setInput] = useState("")
    const normalizedSuggestion = normalizeQuery(input, "suggestion")
    const [showPopout, setShowPopout] = useState(true)
    const [showSearch, setShowSearch] = useState(false)
    const router = useRouter()
    const theme = useTheme()
    const isLg = useMediaQuery(theme.breakpoints.up("md"))
    const searchQueryFromRouter = (router.query.q as string)?.trim()
    const { publicRuntimeConfig } = getConfig()

    const onKeyDown = (e: KeyboardEvent) => {
        if (e.key === "Delete" || e.key === "Escape") setInput("")
    }

    useEffect(() => {
        if (showPopout && searchQueryFromRouter) setInput(searchQueryFromRouter)
    }, [showPopout, searchQueryFromRouter])

    const debouncedQuery = useDebounce(normalizedSuggestion, 200)
    const headerSearchData = useHeaderSearchData(
        normalizedSuggestion,
        debouncedQuery
    )

    const onInputChange = (
        _event: SyntheticEvent<Element, Event>,
        value: string
    ) => {
        setShowPopout(false)
        setInput(value)
    }

    const objectIsSearchLabel = (label: any) => {
        if (typeof label === "string" || label instanceof String) return false
        if (!label) return false
        return true
    }

    const onChange = (
        _event: SyntheticEvent<Element, Event>,
        value:
            | NonNullable<string | ISearchHeaderItem>
            | (string | ISearchHeaderItem)[]
            | null
    ) => {
        const isSearchLabel = objectIsSearchLabel(value)
        const searchLabel = isSearchLabel ? (value as ISearchHeaderItem) : null
        if (searchLabel?.url) {
            setInput("")
            setShowPopout(false)
            setShowSearch(false)
            router.push(searchLabel.url)
        } else {
            setShowPopout(false)
            setShowSearch(false)
            router.push(
                `${publicRuntimeConfig.searchPath}?q=${searchLabel?.label ?? value
                }`
            )
        }
    }

    if (isLg)
        return (
            <StyledWrapper>
                <Autocomplete
                    input={input}
                    onInputChange={onInputChange}
                    suggestions={headerSearchData.suggestions ?? []}
                    onChange={onChange}
                    setShowPopout={setShowPopout}
                    showPopout={showPopout}
                    objectIsSearchLabel={objectIsSearchLabel}
                    onKeyDown={onKeyDown}
                    headerSearchData={headerSearchData}
                />
            </StyledWrapper>
        )

    return (
        <>
            <StyledIconButton
                onClick={() => setShowSearch(!showSearch)}
                aria-label={t.search.title}>
                {showSearch ? (
                    <Icon name="close" />
                ) : (
                    <StyledSearchIcon name="search" />
                )}
            </StyledIconButton>

            {showSearch && (
                <Autocomplete
                    input={input}
                    onInputChange={onInputChange}
                    suggestions={headerSearchData.suggestions ?? []}
                    onChange={onChange}
                    setShowPopout={setShowPopout}
                    showPopout={showPopout}
                    objectIsSearchLabel={objectIsSearchLabel}
                    onKeyDown={onKeyDown}
                    headerSearchData={headerSearchData}
                />
            )}
        </>
    )
}

function Autocomplete({
    input,
    onInputChange,
    suggestions,
    showPopout,
    setShowPopout,
    onChange,
    objectIsSearchLabel,
    onKeyDown,
    headerSearchData
}: {
    input: string
    onInputChange: (
        event: SyntheticEvent<Element, Event>,
        value: string
    ) => void
    suggestions: ISearchHeaderItem[]
    showPopout: boolean
    setShowPopout: (value: SetStateAction<boolean>) => void
    onChange: (
        event: SyntheticEvent<Element, Event>,
        value:
            | NonNullable<string | ISearchHeaderItem>
            | (string | ISearchHeaderItem)[]
            | null
    ) => void
    objectIsSearchLabel: (label: any) => boolean
    onKeyDown: (e: any) => void
    headerSearchData: any
}) {
    return (
        <StyledAutocomplete<ISearchHeaderItem, boolean, boolean, boolean>
            multiple={false}
            inputValue={input}
            filterOptions={options => options}
            onInputChange={onInputChange}
            options={suggestions}
            getOptionLabel={(option: ISearchHeaderItem | string) => {
                if (objectIsSearchLabel(option))
                    return (option as ISearchHeaderItem)?.label ?? input
                return option as string
            }}
            groupBy={(option: ISearchHeaderItem) => option.group}
            freeSolo
            disableClearable
            renderInput={params => (
                <>
                    <StyledInputBase
                        {...params}
                        label={t.search.title}
                        InputLabelProps={{ disableAnimation: true }}
                        aria-hidden="false"
                        onKeyDown={onKeyDown}
                    />
                    {headerSearchData.isLoading && input.length > 1 && (
                        <StyledProgress
                            color="primary"
                            size={35}
                        />
                    )}
                </>
            )}
            renderGroup={props => (
                <li key={props.key}>
                    <GroupHeader variant="body1">{props.group}</GroupHeader>
                    <GroupItems>{props.children}</GroupItems>
                </li>
            )}
            renderOption={(props, option, { inputValue }) => {
                const matches = match(option.label, inputValue)
                const parts = parse(option.label, matches)

                const text = (
                    <StyledResult
                        variant="body2"
                        color="textPrimary">
                        {parts.map((part, index) => (
                            <StyledResultText
                                showhighlight={
                                    part.highlight ? "true" : "false"
                                }
                                // Letters in a search query can be the same, so we have to use index.
                                // eslint-disable-next-line react/no-array-index-key
                                key={part.text + index}>
                                {part.text}
                            </StyledResultText>
                        ))}
                    </StyledResult>
                )
                return (
                    <li
                        {...props}
                        color="inherit">
                        {text}
                    </li>
                )
            }}
            open={input.length > 0 && !showPopout}
            onChange={onChange}
            clearOnBlur={true}
            onFocus={() => setShowPopout(false)}
            onBlur={() => setShowPopout(true)}
        />
    )
}

const StyledAutocomplete = styled(MUIAutocomplete)(({ theme }) => ({
    width: "100vw",
    top: theme.spacing(7),
    right: theme.spacing(-2),
    position: "absolute",
    backgroundColor: theme.palette.common.white,
    border: `${theme.spacing(0.5)} solid ${theme.palette.secondary.main}`,
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        border: "none"
    },
    [theme.breakpoints.up("sm")]: {
        position: "absolute",
        top: theme.spacing(10),
        width: "93vw",
        right: theme.spacing(0)
    },
    [theme.breakpoints.up("md")]: {
        width: theme.spacing(38),
        height: theme.spacing(5),
        position: "static",
        display: "flex",
        alignItems: "center",
        border: "none"
    }
})) as typeof MUIAutocomplete

const GroupHeader = styled(Typography)(({ theme }) => ({
    position: "sticky",
    top: theme.spacing(-1),
    padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
    color: theme.palette.grey[600],
    backgroundColor: theme.palette.common.white
}))

const GroupItems = styled("ul")(({ theme }) => ({
    paddingLeft: theme.spacing(0)
}))

const StyledResult = styled(Typography)(({ theme }) => ({
    paddingLeft: theme.spacing(2)
}))

const StyledResultText = styled("span")<{ showhighlight: string }>(
    ({ theme, showhighlight }) => ({
        fontWeight: showhighlight === "true" ? 600 : 400,
        color:
            showhighlight === "true"
                ? theme.palette.text.primary
                : theme.palette.grey[800]
    })
)

const StyledWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    position: "relative",
    border: `${theme.spacing(0.3)} solid ${theme.palette.secondary.main}`,
    backgroundColor: theme.palette.common.white,
    height: theme.spacing(6)
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.common.white,
    "& .MuiSvgIcon-root": {
        width: theme.spacing(4),
        height: theme.spacing(4),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(6),
            height: theme.spacing(6)
        }
    }
}))

const StyledSearchIcon = styled(Icon)(({ theme }) => ({
    fontSize: "2.5rem",
    marginTop: theme.spacing(0),
    position: "static",
    marginRight: theme.spacing(0),
    color: theme.palette.common.white,
    stroke: theme.palette.common.white
}))

const StyledProgress = styled(CircularProgress)(({ theme }) => ({
    position: "absolute",
    zIndex: 999,
    right: theme.spacing(1),
    top: theme.spacing(1.3),
    color: theme.palette.secondary.main,
    stroke: theme.palette.secondary.main,
    [theme.breakpoints.up("md")]: {
        top: theme.spacing(0.4)
    }
}))

const StyledInputBase = styled(TextField)(({ theme }) => ({
    color: theme.palette.common.white,
    borderRadius: 0,
    "& .MuiInputLabel-root": {
        color: theme.palette.grey[500],
        "&.MuiInputLabel-shrink": {
            display: "none"
        }
    },
    "& .MuiOutlinedInput-root": {
        height: "60%",
        width: "100%"
    },
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 4, 1, 3)
    }
}))
