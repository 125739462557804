// eslint-disable-next-line no-restricted-imports
import NextLink from "next/link"
import { ReactNode } from "react"

import MUILink, { LinkProps } from "@mui/material/Link"
import { styled } from "@mui/material/styles"

import { isInternalLink, isNonNavigational } from "@nhi/utils"

import Icon from "./Icon/Icon"

export interface ILinkProps extends LinkProps {
    href: string
    children: ReactNode
    prefetch?: boolean
    disabled?: boolean
}

export default function Link(props: ILinkProps) {
    const { href, children, prefetch = false, disabled, ...rest } = props

    let hostname: string | undefined = undefined

    // Fake "nhi.no" hostname if we're running on localhost
    if (typeof window !== "undefined")
        hostname =
            window.location.hostname === "localhost"
                ? "nhi.no"
                : window.location.hostname

    if (isInternalLink(href, hostname)) {
        if (disabled) return <span>{children}</span>

        return (
            <NextLink
                href={href}
                passHref
                prefetch={prefetch}>
                <MUILink
                    {...(rest as LinkProps)}
                    underline="hover">
                    {children}
                </MUILink>
            </NextLink>
        )
    }

    const actAsInternal = process.env.NEXT_PUBLIC_ACT_AS_INTERNAL.split(
        ","
    ).some(domain => href.includes(domain))

    if (isNonNavigational(href) || actAsInternal)
        return (
            <MUILink
                href={href}
                {...(rest as LinkProps)}
                underline="hover">
                {children}
            </MUILink>
        )

    return (
        <StyledExternalLink
            target="_blank"
            color={"inherit"}
            href={href}
            {...(rest as LinkProps)}
            underline="always">
            {children} <LinkIcon href={href} />
        </StyledExternalLink>
    )
}

function LinkIcon({ href }: { href: string }) {
    if (href?.toLowerCase().endsWith(".pdf"))
        return (
            <Icon
                name="pdf"
                fontSize={"inherit"}
            />
        )

    return (
        <Icon
            name="externalLink"
            fontSize={"inherit"}
        />
    )
}

const StyledExternalLink = styled(MUILink)(({ theme }) => ({
    display: "inline",
    alignItems: "center",
    gap: theme.spacing(0.5),
    color: theme.palette.primary.link,
    textUnderlineOffset: "0.1em",
    "&:hover": {
        textDecoration: "none"
    }
}))
