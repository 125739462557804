import { middlewareTopics } from "middleware-topics/middleware-topics"
import { useRouter } from "next/router"
import React from "react"
import { t } from "translations"

import Checkbox from "@mui/material/Checkbox"
import Container from "@mui/material/Container"
import FormControlLabel from "@mui/material/FormControlLabel"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import useUserCategory from "Components/UserCategoryModal/useUserCategory"

export default function UserCategoryCheckbox() {
    const { userCategory, setUserCategory } = useUserCategory()
    const router = useRouter()

    function shouldRedirect(toPro: boolean) {
        let path = router.asPath

        if (toPro && path.startsWith("/pro")) return false
        if (!toPro && !path.startsWith("/pro")) return false

        const index = path.indexOf("?")
        if (index > -1) path = path.slice(0, index)

        const isInMiddlewareTopics = middlewareTopics.some(x => x == path)
        return isInMiddlewareTopics
    }

    const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setUserCategory("professional")
            const shouldRedirectTrue = shouldRedirect(true)
            if (shouldRedirectTrue)
                window.location.pathname = `/pro${router.asPath}`
        } else {
            setUserCategory("layman")
            const shouldRedirectFalse = shouldRedirect(false)
            if (shouldRedirectFalse)
                window.location.pathname =
                    router.asPath.replace("/pro", "") ?? "/"
        }
    }

    return (
        <StyledCheckboxContainer>
            <Container maxWidth="lg">
                <FormControlLabel
                    control={
                        <StyledCheckbox
                            color="primary"
                            checked={userCategory === "professional"}
                            onChange={handleCheckbox}
                        />
                    }
                    label={
                        <Typography color="inherit">
                            {t.header.usertypeCheckbox}
                        </Typography>
                    }
                />
            </Container>
        </StyledCheckboxContainer>
    )
}

const StyledCheckboxContainer = styled("div")(({ theme }) => ({
    gridColumn: "span 4/4",
    borderBottom: `1px solid ${theme.palette.common.white}`,
    padding: theme.spacing(2, 0),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white
}))

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    color: theme.palette.common.white,
    "&.Mui-checked": {
        color: theme.palette.common.white
    }
}))
