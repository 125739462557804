// eslint-disable-next-line no-restricted-imports
import NextImage, { ImageProps } from "next/future/image"
import { useContext } from "react"

import { styled } from "@mui/material/styles"

import DownloadImageWrapper, {
    ImageDownloadContext
} from "./DownloadImageWrapper"

interface IImageProps extends Omit<ImageProps, "alt"> {
    alt: string | undefined
}

export default function Image({
    src,
    alt,
    title,
    width,
    height,
    fill,
    loading,
    ...rest
}: IImageProps) {
    const { showImageDownload } = useContext(ImageDownloadContext)

    if (showImageDownload === true) {
        if (fill)
            return (
                <DownloadImageWrapper
                    src={src}
                    title={title}>
                    <StyledFillNextImage
                        src={src}
                        alt={alt ?? ""}
                        title={title}
                        width="100"
                        height="100"
                        sizes="100vw"
                        loading={loading}
                        {...rest}
                    />
                </DownloadImageWrapper>
            )

        if (width && height)
            return (
                <DownloadImageWrapper
                    src={src}
                    title={title}>
                    <StyledNextImage
                        src={src}
                        alt={alt ?? ""}
                        title={title}
                        width={width}
                        height={height}
                        loading={loading}
                        {...rest}
                    />
                </DownloadImageWrapper>
            )

        return (
            <DownloadImageWrapper
                src={src}
                title={title}>
                <StyledImage
                    src={src as string}
                    alt={alt}
                    title={title}
                    {...rest}
                    loading={loading}
                />
            </DownloadImageWrapper>
        )
    }
    if (fill)
        return (
            <StyledFillNextImage
                src={src}
                alt={alt ?? ""}
                title={title}
                width="100"
                height="100"
                sizes="100vw"
                loading={loading}
                {...rest}
            />
        )

    if (width && height)
        return (
            <StyledNextImage
                src={src}
                alt={alt ?? ""}
                title={title}
                width={width}
                height={height}
                loading={loading}
                {...rest}
            />
        )

    return (
        <StyledImage
            src={src as string}
            alt={alt}
            title={title}
            {...rest}
            loading={loading}
        />
    )
}

const StyledFillNextImage = styled(NextImage)(() => ({
    width: "100%",
    height: "auto"
}))

const StyledNextImage = styled(NextImage)(() => ({
    maxWidth: "100%",
    height: "auto"
}))

const StyledImage = styled("img")(() => ({
    maxWidth: "100%"
}))
