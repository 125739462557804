import { IImage } from "data/contentData/interfaces/mongodb/IImage"

import Image from "./Image"

export default function ContentImage({
    image,
    loading,
    id,
    ...rest
}: {
    image: IImage | undefined
    loading?: "lazy" | "eager" | undefined
    id?: string
}) {
    if (!image || !image.src) return null

    const { src, alt, title, width, height } = image

    return (
        <Image
            src={src}
            alt={alt}
            title={title}
            width={width}
            height={height}
            fill
            loading={loading}
            id={id}
            {...rest}
        />
    )
}
