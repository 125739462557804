import { IPage } from "data/contentData/interfaces/mongodb/IPage"
import { IPublic } from "data/contentData/interfaces/mongodb/IPublic"

/**
 * Get the third parent page from a nested page
 * @param page The page to get parent from
 * @param atPos Default topic position is at -2, but for CM articles it is -3
 * @returns The third parent page
 */
export function getParentTopic(page: IPublic, atPos = -2): IPage | undefined {
    if (!page) return undefined
    const parent =
        page.parents && page.parents?.length > 1
            ? page.parents.at(atPos)
            : undefined
    return parent
}
