/* eslint-disable react-hooks/exhaustive-deps */
import { PortableTextTypeComponentProps } from "@portabletext/react"
import { RefObject, useEffect, useRef } from "react"

interface EasyLMS {
    id: string
    kind: "quiz" | "exam" | "course"
}

export default function EasyLMS(
    props: PortableTextTypeComponentProps<EasyLMS>
) {
    const { kind, id } = props.value

    const scriptBlock = useRef<HTMLDivElement>(null)
    useScriptBlock(kind, id, scriptBlock)

    const scriptWithUrl = useRef<HTMLDivElement>(null)
    useScriptUrl(
        "https://d134jvmqfdbkyi.cloudfront.net/script/embed.min.js",
        scriptWithUrl
    )

    return (
        <>
            <div id={`embed-${kind}-${id}`} />
            <div ref={scriptBlock} />
            <div ref={scriptWithUrl} />
        </>
    )
}

const useScriptBlock = (
    kind: string,
    id: string,
    ref: RefObject<HTMLDivElement>
) => {
    useEffect(() => {
        const script = document.createElement("script")

        script.async = true
        script.text = `var QuizWorks=window.QuizWorks||[];QuizWorks.push([document.querySelector("#embed-${kind}-${id}"),"${kind}",${id},{autostart:!1,width:"100%",height:"640px"}]);`

        if (ref.current) ref.current.appendChild(script)

        return () => {
            if (ref.current) ref.current.removeChild(script)
        }
    }, [kind, id, ref])
}

const useScriptUrl = (url: string, ref: RefObject<HTMLDivElement>) => {
    useEffect(() => {
        const script = document.createElement("script")

        script.src = url
        script.async = true

        if (ref.current) ref.current.appendChild(script)

        return () => {
            if (ref.current) ref.current.removeChild(script)

            window.QuizWorks = null
        }
    }, [url, ref])
}
