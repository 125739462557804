import { useRouter } from "next/router"
import { useEffect, useState } from "react"

import { IArticle } from "data/contentData/interfaces/mongodb/IArticle"
import { IPage } from "data/contentData/interfaces/mongodb/IPage"
import { IPublic } from "data/contentData/interfaces/mongodb/IPublic"
import { getParentTopic } from "lib/getParentTopic"

import useUserCategory from "Components/UserCategoryModal/useUserCategory"

export interface IGamValues {
    env: "prod" | "test"
    site: string
    section: string
    context: string
    tags: string
}

export default function useGetGAMValues(page: IPage) {
    const { userCategory } = useUserCategory()
    const router = useRouter()

    const [values, setValues] = useState<IGamValues>({
        env: process.env.NODE_ENV === "production" ? "prod" : "test",
        site: "",
        section: "",
        context: "",
        tags: ""
    })

    useEffect(() => {
        if (page && page.types && userCategory) {
            const site = userCategory === "professional" ? "pro" : "public"
            const section =
                router.asPath === "/" || router.asPath === "/pro"
                    ? "Forside"
                    : getParentTopic(page as IPublic)?.name ?? ""

            const context = page.types?.slice(-1).join() ?? ""
            const customTag = [getTag(page.url)]
            const pageTags = (page as IArticle).tags ?? []
            const combined = [...customTag, ...pageTags]
            const tags = combined.filter(x => x).join(",")

            setValues(p => ({
                ...p,
                site,
                section,
                context,
                tags
            }))
        }
    }, [page, router, userCategory])

    return values
}

/**
 * customise tag based on URL
 * @param url page url
 * @returns tag
 */
function getTag(url: string) {
    if (url.includes("/akuttmedisin/")) return "Akuttmedisin"
    if (url.includes("/allergi/")) return "Allergi"
    if (url.includes("/barn/")) return "Barn"
    if (url.includes("/blod/")) return "Blod"
    if (url.includes("/egenomsorg/")) return "Egenomsorg"
    if (url.includes("/eldre/")) return "Eldre"
    if (url.includes("/hjernenervesystem/")) return "Hjerne- og nervesystem"
    if (url.includes("/hjerne-og-nervesystem/")) return "Hjerne- og nervesystem"
    if (url.includes("/hjertekar/")) return "Hjerte og kar"
    if (url.includes("/hjerte-og-kar/")) return "Hjerte og kar"
    if (url.includes("/hormoner-og-naring")) return "Hormoner og næring"
    if (url.includes("/hud/")) return "Hud"
    if (url.includes("/graviditet/")) return "Graviditet"
    if (url.includes("/graviditetsorakelet/")) return "Graviditet"
    if (url.includes("/infeksjoner/")) return "Infeksjoner"
    if (url.includes("/kirurgi/")) return "Kirurgi"
    if (url.includes("/ernaring/")) return "Kosthold og ernæring"
    if (url.includes("/kosthold/")) return "Kosthold og ernæring"
    if (url.includes("/kreft/")) return "Kreft"
    if (url.includes("/kropp/")) return "Kropp"
    if (url.includes("/kroppen-var/")) return "Kropp"
    if (url.includes("/kvinne/")) return "Kvinnehelse"
    if (url.includes("/lunger/")) return "Lunger"
    if (url.includes("/magetarm/")) return "Mage og tarm"
    if (url.includes("/mann/")) return "Mannehelse"
    if (url.includes("/muskelskjelett/")) return "Muskel og skjelett"
    if (url.includes("/nyrerurinveier/")) return "Nyrer og urinveier"
    if (url.includes("/nyrer-og-urinveier/")) return "Nyrer og urinveier"
    if (url.includes("/psykisk-helse/")) return "Psykisk helse"
    if (url.includes("/psyke-og-sinn/")) return "Psykisk helse"
    if (url.includes("/reise/")) return "Reise"
    if (url.includes("/sjeldne-tilstander/")) return "Sjeldne tilstander"
    if (url.includes("/trening/")) return "Trening"
    if (url.includes("/orenesehals/")) return "Øre/nese/hals"
    if (url.includes("/oye/")) return "Øye"
    return ""
}
