import { useEffect, useState } from "react"

import { isQueryValid } from "@nhi/utils"
import useQueryData from "data/hooks/useQueryData"
import { autocomplete, suggestion } from "data/searchManager/api/search"

import useUserCategory from "Components/UserCategoryModal/useUserCategory"

import { ISearchHeaderItem } from "./ISearchHeaderItem"

/**
 * Hook for searching both autocomplete and suggestions
 * @param searchQuery The search query to search for, will be used to know when to reset the returned result
 * @param debouncedSearchQuery The debounced search query, will be used to perform the actual search
 * @returns A merged list of the result
 */
function useHeaderSearchData(
    searchQuery: string,
    debouncedSearchQuery: string
) {
    const { userCategory } = useUserCategory()
    const isProfessionalUser = userCategory === "professional"

    const [suggestions, setSuggestions] = useState<ISearchHeaderItem[]>([])

    const validSearchQuery = isQueryValid(debouncedSearchQuery)
        ? debouncedSearchQuery
        : ""

    const { data: searchData, isLoading } = useQueryData<ISearchHeaderItem[]>(
        `header-search-${validSearchQuery}-${isProfessionalUser}`,
        async () => {
            const autocompletesFound = await searchAutocomplete(
                validSearchQuery
            )
            const suggestionsFound = await searchSuggestion(
                validSearchQuery,
                isProfessionalUser
            )

            const searchLabels: ISearchHeaderItem[] =
                autocompletesFound?.concat(suggestionsFound) ?? []
            return searchLabels
        }
    )

    useEffect(() => {
        setSuggestions([])
    }, [searchQuery])

    useEffect(() => {
        setSuggestions(searchData ?? [])
    }, [searchData])

    return { suggestions, isLoading }
}

/**
 * Search for autocomplete based on search query
 * @param validSearchQuery  The query to search for
 * @returns List of autocomplete matches
 */
async function searchAutocomplete(validSearchQuery: string) {
    const autocompletes = await autocomplete(validSearchQuery, 20)

    const autocompleteSearchLabels = autocompletes?.map(
        x =>
            ({
                group: "Søkeord",
                label: x
            } as ISearchHeaderItem)
    )

    return autocompleteSearchLabels
}

/**
 * Search for suggestion based on search query
 * @param validSearchQuery The query to search for
 * @returns List of suggestions matches
 */
async function searchSuggestion(
    validSearchQuery: string,
    isProfessionalUser: boolean
) {
    const suggestions = await suggestion(
        validSearchQuery,
        20,
        isProfessionalUser
    )

    const suggestionSearchLabels = suggestions?.flatMap(
        suggestionOfsuggestions =>
            suggestionOfsuggestions.suggestions.map(
                innerSuggestion =>
                    ({
                        group: suggestionOfsuggestions.name,
                        label: innerSuggestion.name,
                        url: innerSuggestion.url
                    } as ISearchHeaderItem)
            )
    )

    return suggestionSearchLabels ?? []
}

export default useHeaderSearchData
