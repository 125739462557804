import { get } from "@nhi/utils"
import { IArticleCard } from "data/contentData/interfaces/mongodb/IArticleCard"
import { ISearchRoot } from "data/searchManager/interfaces/ISearch"
import { ISuggestionRoot } from "data/searchManager/interfaces/ISuggestion"

/**
 * Search for articles
 * @param query The query to search by
 * @param type Which articles types to search for
 * @param limit Limit search result
 * @param skip skip search result
 * @param sortType sort search result (0 = "best hits", 1 = "date")
 * @returns List of articles
 */
export async function search(
    query: string,
    limit: number,
    skip: number,
    sortType: number,
    templateSubGroup: string | null = null,
    isProfessionalContent = false
) {
    if (!query) return undefined

    const endpoint =
        `${process.env.NEXT_PUBLIC_SEARCHMANAGER_API}/search` +
        `?query=${encodeURIComponent(query)}` +
        `${
            templateSubGroup
                ? `&templateSubGroup=${encodeURIComponent(
                      templateSubGroup ?? ""
                  )}`
                : ""
        }` +
        `&limit=${limit}` +
        `&skip=${skip}` +
        `&sortType=${sortType}` +
        `&isProfessionalContent=${isProfessionalContent}`

    const { data } = await get<ISearchRoot>(endpoint)
    return data
}

/**
 * Search for article suggestions
 * @param query The query to search by
 * @param limit Limit search result
 * @returns List of articles
 */
export async function suggestion(
    query: string,
    limit: number,
    isProfessionalContent = false
) {
    if (!query) return [] as ISuggestionRoot[]

    const endpoint =
        `${process.env.NEXT_PUBLIC_SEARCHMANAGER_API}/suggestion` +
        `?query=${encodeURIComponent(query)}` +
        `&limit=${limit}` +
        `&isProfessionalContent=${isProfessionalContent}`

    const { data } = await get<ISuggestionRoot[]>(endpoint)
    return data
}

/**
 * Search for autocomplete words
 * @param query The query to search by
 * @param limit Limit search result
 * @returns List of words
 */
export async function autocomplete(query: string, limit: number) {
    if (!query) return [] as string[]

    const endpoint =
        `${process.env.NEXT_PUBLIC_SEARCHMANAGER_API}/autocomplete` +
        `?query=${encodeURIComponent(query)}` +
        `&limit=${limit}`

    const { data } = await get<string[]>(endpoint)
    return data
}

/**
 * Endpoint for searching related articles
 * @param path Related path
 * @returns list of matching articles
 */
export async function getRelatedArticles(
    contentId: number,
    filterPath: string,
    currentArticleUrl: string,
    limit = 4,
    isProfessionalUser = false,
    includeContentMarketing = false
) {
    if (!contentId || !filterPath) return []

    const endpoint =
        `${process.env.NEXT_PUBLIC_SEARCHMANAGER_API}/articles/related` +
        `?contentId=${contentId}` +
        `&filterPath=${encodeURIComponent(filterPath)}` +
        `&limit=${limit + 1}` +
        `&isProfessionalUser=${isProfessionalUser}` +
        `&includeContentMarketing=${includeContentMarketing}`

    const { data } = await get<IArticleCard[]>(endpoint)

    return data?.filter(x => x.url !== currentArticleUrl).slice(0, limit) ?? []
}
