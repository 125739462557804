import { PortableTextMarkComponent } from "@portabletext/react"

import { styled } from "@mui/material/styles"

import Icon from "Components/Icon/Icon"
import Link from "Components/Link"

const ContentLink: PortableTextMarkComponent = ({
    children: _children,
    value
}) => {
    const children =
        Array.isArray(_children) && _children.length > 0
            ? _children[0]
            : _children

    const mark = value

    if (mark.class?.includes("highlighted"))
        return (
            <StyledHighlightedLink
                color="secondary"
                href={mark.href}
                target={mark.target}>
                <Icon name="link" />
                {children}
            </StyledHighlightedLink>
        )

    return <StyledContentLink href={value.href}>{children}</StyledContentLink>
}

export default ContentLink

const StyledHighlightedLink = styled(Link)(({ theme }) => ({
    textDecoration: "underline",
    border: `1px solid ${theme.palette.secondary.main}`,
    fontWeight: 700,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(2),
    width: "100%",
    padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
    "&:hover": {
        textDecoration: "none",
        background: theme.palette.secondary[50]
    }
}))

const StyledContentLink = styled(Link)(({ theme }) => ({
    color: theme.palette.primary.link
}))
