import { PortableTextTypeComponentProps } from "@portabletext/react"

import AdInstream, { AdInstreamType } from "Components/Ad/AdInstream"
import usePageDataProvider, { IPagingContext } from "Components/Layout/PageComponent/PageDataProvider"

interface IInstreamAdProps {
    adUnitType: string
    adUnitUsage: string
}

export default function InstreamAd(
    props: PortableTextTypeComponentProps<IInstreamAdProps>
) {
    const { adUnitType, adUnitUsage } = props.value
    const paging = usePageDataProvider()

    if (!showAd(paging, adUnitUsage)) return null

    return (
        <AdInstream type={adUnitType as AdInstreamType} />
    )
}



/**
 * Decides whether to show ad or not
 * @param paginate Article should be paginated
 * @param pageParam Page parameter in query string
 * @param usage String containing comma separated values of usage options
 * @returns boolean indicating whether to show ad or not
 */
function showAd(paging: IPagingContext | null, usage: string) {
    if (!usage) return true

    const usageArr = usage?.split(",") ?? []

    // Page has paging but user has selected to show all
    const isPaged = paging !== null

    if (usageArr.includes("paged") && isPaged) return true
    if (usageArr.includes("non-paged") && !isPaged) return true

    return false
}
