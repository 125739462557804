import React, { MouseEvent, useState } from "react"
import { t } from "translations"

import Container from "@mui/material/Container"
import IconButton from "@mui/material/IconButton"
import Popover from "@mui/material/Popover"
import { styled } from "@mui/material/styles"

import { IMegamenuCategory } from "data/contentData/interfaces/IMegamenuCategory"

import Icon from "Components/Icon/Icon"

import MegamenuItem from "./MegamenuItem"
import UserCategoryCheckbox from "./UserCategoryCheckbox"

export default function Megamenu({ data }: { data: IMegamenuCategory[] }) {
    const [popoverElement, setPopoverElement] =
        useState<HTMLButtonElement | null>(null)

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setPopoverElement(event.currentTarget)
    }

    const handleClose = () => {
        setPopoverElement(null)
    }

    const open = Boolean(popoverElement)

    return (
        <>
            <StyledIconButton
                onClick={handleClick}
                aria-label={t.header.megaMenuButton}>
                {open ? <Icon name="close" /> : <Icon name="menu" />}
            </StyledIconButton>

            <StyledPopover
                open={open}
                anchorEl={popoverElement}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                marginThreshold={0}>
                <UserCategoryCheckbox />
                <Container maxWidth="lg">
                    <StyledWrapper>
                        {data?.map(item => (
                            <MegamenuItem
                                key={item.name}
                                item={item}
                                handleClose={handleClose}
                            />
                        ))}
                    </StyledWrapper>
                </Container>
            </StyledPopover>
        </>
    )
}

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    borderRadius: 0,
    background: theme.palette.secondary.main,
    color: theme.palette.common.white,
    height: theme.spacing(6.75),
    width: theme.spacing(6),
    "& .MuiSvgIcon-root": {
        width: theme.spacing(6),
        height: theme.spacing(6)
    },
    "&.MuiButtonBase-root:hover": {
        background: theme.palette.secondary.dark,
        color: theme.palette.common.white
    },
    [theme.breakpoints.up("sm")]: {
        width: theme.spacing(6),
        height: theme.spacing(10)
    }
}))

const StyledPopover = styled(Popover)(({ theme }) => ({
    "& .MuiPopover-paper": {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 0,
        maxHeight: "80vh",
        width: "100vw",
        maxWidth: "100vw",
        boxShadow:
            "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14)",
        MozBoxShadow:
            "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14)",
        WebkitBoxShadow:
            "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14)"
    }
}))

const StyledWrapper = styled("div")(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(4, 0),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
        display: "grid",
        gridTemplateColumns: "1fr 2fr 1fr",
        flexDirection: "row"
    }
}))
