import { PortableTextTypeComponentProps } from "@portabletext/react"

import Button, { ButtonProps } from "@mui/material/Button"

interface IContentButtonProps extends Pick<ButtonProps, "fullWidth" | "title"> {
    method: string
    style: string
    text: JSX.Element
    url: string
    id: string | null
}

export default function ContentButton(
    props: PortableTextTypeComponentProps<IContentButtonProps>
) {
    const { url: href, style, text: children, id: id, ...rest } = props.value

    const color: ButtonProps["color"] =
        style === "primary" ? "primary" : "secondary"

    return (
        <Button
            className="content-button"
            disableRipple
            variant="outlined"
            color={color}
            href={href}
            id={id ?? ""}
            {...rest}>
            {children}
        </Button>
    )
}
