import { grey } from "@mui/material/colors"
import { styled, useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

import { useAdContext } from "./AdContext"
import AdTitle from "./AdTitle"

export type AdType =
    | "toppbanner" // article && topics: etter 2 items
    | "m_bunnbanner_sticky" // all pages: nederst på siden
    | "netboard1" // topics: etter 8 items
    | "netboard2" // topics: etter 11 items
    | "netboard3" // topics: etter 14 items
    | "artikkelboard1" // article: i toppen på right aside
    | "artikkel_skyskraper" // article: i right aside ETTER 2 widgets
    | "artikkel_instream1" // article: første reklame
    | "artikkel_instream2" // article: andre reklame
    | "venstre_skyskraper"
    | "hoyre_skyskraper"

export default function Ad({ type, format = null, collapseEmpty = null }: { type: AdType, format?: string | null, collapseEmpty?: "" | null }) {
    const { showAds } = useAdContext()
    const theme = useTheme()
    const mdUp = useMediaQuery(theme.breakpoints.up("md"))

    if (!showAds) return null

    const adUnitId = `/${process.env.NEXT_PUBLIC_GAM_ID}/${type}`

    const height = AD_HEIGHTS[mdUp ? "desktop" : "mobile"][type]

    return (
        <AdWrapper>
            <Title>
                <AdTitle />
            </Title>
            <StyledAd
                data-ad-unit-id={adUnitId}
                data-ad-format={format}
                data-collapse-empty-divs={collapseEmpty}
                height={`${height}px`}
            />
        </AdWrapper>
    )
}

const AD_HEIGHTS = {
    mobile: {
        toppbanner: 300,
        m_bunnbanner_sticky: 300,
        netboard1: 300,
        netboard2: 300,
        netboard3: 300,
        artikkelboard1: 300,
        artikkel_skyskraper: 600,
        artikkel_instream1: 250,
        artikkel_instream2: 250,
        venstre_skyskraper: 600,
        hoyre_skyskraper: 600
    },
    desktop: {
        toppbanner: 300,
        m_bunnbanner_sticky: 300,
        netboard1: 300,
        netboard2: 300,
        netboard3: 300,
        artikkelboard1: 300,
        artikkel_skyskraper: 600,
        artikkel_instream1: 400,
        artikkel_instream2: 400,
        venstre_skyskraper: 600,
        hoyre_skyskraper: 600
    }
} as const

const AdWrapper = styled("div")(() => ({
    position: "relative",
    clear: "both"
}))

const StyledAd = styled("div")<{ height: string }>(({ height }) => ({
    position: "relative",
    display: "flex",
    justifyContent: "center",
    minHeight: height
}))

const Title = styled("span")(() => ({
    color: grey[700]
}))
