import { PortableTextTypeComponentProps } from "@portabletext/react"
import { useEffect, useRef, useState } from "react"
import { t } from "translations"

import MuiTable from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import PortableText from "Components/PortableText/PortableText"

interface ITableProps {
    caption?: string
    rows: Array<
        Array<{
            blockContent: string
            colSpan?: number
            rowSpan?: number
            style?: string
        }>
    >
    style?: string
}

export default function Table(
    props: PortableTextTypeComponentProps<ITableProps>
) {
    const data = props.value

    const rows = data.rows.map((row, index) => {
        return {
            row,
            key: index
        }
    })

    const classes = props.value.style?.split(",").join(" ") ?? ""

    const ref = useRef<HTMLDivElement | null>(null)
    const [hasScrollBar, setHasScrollBar] = useState(false)

    useEffect(() => {
        function updateState() {
            ref.current &&
                setHasScrollBar(
                    ref.current.scrollWidth >
                        ref.current.getBoundingClientRect().width
                )
        }

        updateState()
        window.addEventListener("resize", updateState)
        return () => window.removeEventListener("resize", updateState)
    }, [])
    return (
        <>
            {hasScrollBar && (
                <Typography variant="caption">
                    {t.table.scrollToView}
                </Typography>
            )}

            <StyledTableContainer
                ref={ref}
                id="table">
                <StyledMuiTable className={classes}>
                    {data.caption && (
                        <caption>
                            <PortableText value={JSON.parse(data.caption)} />
                        </caption>
                    )}
                    <TableBody>
                        {rows.map(tableRow => (
                            <TableRow key={tableRow.key}>
                                {tableRow.row.map(
                                    ({ blockContent, style, ...trProps }) => (
                                        <StyledTableCell
                                            boldtext={style ?? ""}
                                            key={blockContent}
                                            {...trProps}>
                                            <PortableText
                                                value={JSON.parse(blockContent)}
                                            />
                                        </StyledTableCell>
                                    )
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </StyledMuiTable>
            </StyledTableContainer>
        </>
    )
}

const StyledTableContainer = styled("div")(({ theme }) => ({
    wordBreak: "keep-all",
    margin: theme.spacing(1, 0),
    overflowX: "auto",
    width: "92vw",
    "&::-webkit-scrollbar": {
        height: "12px"
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.secondary.main
    },

    "&::-webkit-scrollbar-track": {
        marginTop: "2px",
        backgroundColor: theme.palette.background?.default
    },
    [theme.breakpoints.up("sm")]: {
        width: "auto"
    }
}))

const StyledMuiTable = styled(MuiTable)({
    margin: 0,
    width: "100%",
    wordBreak: "keep-all"
})

const StyledTableCell = styled(TableCell)<{ boldtext: string }>(
    ({ theme, boldtext }) => ({
        border: `${theme.spacing(0.06)} solid ${theme.palette.grey[500]}`,
        padding: theme.spacing(1, 1),
        verticalAlign: "top",
        fontSize: "14px",
        fontWeight: boldtext === "em" ? 700 : 400
    })
)
