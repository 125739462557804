import dynamic from "next/dynamic"
import { ReactNode } from "react"

import Container from "@mui/material/Container"
import { styled, useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

import { IMegamenuCategory } from "data/contentData/interfaces/IMegamenuCategory"
import { IContainerCard } from "data/contentData/interfaces/mongodb/IContainerCard"

import FooterComponent from "./Footer"
import Header from "./Header"

const SkipToContent = dynamic(() => import("./SkipToContent"), {
    ssr: false
})

export default function Layout({
    hideSkipToContent,
    children,
    footer,
    megamenu
}: {
    hideSkipToContent?: boolean
    children: ReactNode
    footer: IContainerCard[]
    megamenu: IMegamenuCategory[]
}) {
    const theme = useTheme()
    const smUp = useMediaQuery(theme.breakpoints.up("sm"))

    return (
        <StyledWrapper>
            {!hideSkipToContent && <SkipToContent />}
            <Header megamenu={megamenu} />
            <Container
                id="layout-container"
                disableGutters={!smUp}
                maxWidth="lg"
                sx={{ position: "relative" }}>
                {children}
            </Container>
            <FooterComponent footerData={footer} />
        </StyledWrapper>
    )
}

const StyledWrapper = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.default
}))
