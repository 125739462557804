import {
    PortableTextProps,
    PortableTextReactComponents,
    PortableText as SanityPortableText
} from "@portabletext/react"

import ContentButton from "./Serializers/ContentButton"
import ContentLink from "./Serializers/ContentLink"
import EasyLMS from "./Serializers/EasyLMS"
import Image from "./Serializers/GalleryImage"
import ImageList from "./Serializers/ImageList"
import InlineReference from "./Serializers/InlineReference"
import InstreamAd from "./Serializers/InstreamAd"
import Note from "./Serializers/Note"
import Survey from "./Serializers/Survey"
import Table from "./Serializers/Table"
import VideoEmbed from "./Serializers/VideoEmbed"
import CalculatorSerializer from "./Serializers/Calculators/Calculator"

export const defaultComponents: Partial<PortableTextReactComponents> = {
    marks: {
        link: ContentLink,
        subscript: ({ children }) => <sub>{children}</sub>,
        superscript: ({ children }) => <sup>{children}</sup>
    },
    types: {
        button: ContentButton,
        image: Image,
        table: Table,
        calculator: CalculatorSerializer,
        ref: InlineReference,
        "video-embed": VideoEmbed,
        "easy-lms": EasyLMS,
        "ad-unit": InstreamAd,
        "image-list": ImageList,
        note: Note,
        survey: Survey
    }
}

interface IPortableTextProps extends PortableTextProps {
    customComponents?: Partial<PortableTextReactComponents>
}

export default function PortableText(props: IPortableTextProps) {
    const components: Partial<PortableTextReactComponents> = {
        marks: {
            ...defaultComponents.marks,
            ...props.customComponents?.marks
        },
        types: {
            ...defaultComponents.types,
            ...props.customComponents?.types
        }
    }

    return (
        <SanityPortableText
            value={props.value}
            components={components}
        />
    )
}
