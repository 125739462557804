import { translation } from "./translations"

export const t = translation

/**
 * Replaces dynamic parts in the string, using handlebars ("{{key}}")
 * @example
 * ```
 * const t = replace("Search: {{query}}", {query: "foo"})
 * console.log(t) // Search: foo
 * ```
 */
export function replace(translationKey: string, values: Record<string, any>) {
    if (!translationKey) return "Translation not found"

    Object.entries(values).forEach(([key, value]) => {
        translationKey = translationKey.replace(
            `{{${key}}}`,
            value?.toString() ?? ""
        )
    })
    return translationKey
}

/**
 * Find all occurences of a value in a string
 * @param translationKey the translation key to search
 * @param valueToFind the value to find
 * @returns an index with all the indexes of the valueToFind in the translationKey
 */
function getAllIndexes(translationKey: string, valueToFind: string) {
    const indexes: number[] = []
    let i = -1

    while ((i = translationKey.indexOf(valueToFind, i + 1)) != -1)
        indexes.push(i)

    return indexes
}

/**
 * Replace all dynamic parts in the string, using handlebars ("{{key}}") with components
 * @param translationKey the translation key to perform replacement of values with components
 * @param replacements the list of values and components to replace
 * @returns an array with strings and components that can be rendered
 */
export function replaceWithComponents(
    translationKey: string,
    replacements: Record<string, React.ReactNode>
) {
    let indexForValue: {
        index: number
        value: React.ReactNode
        key: string
    }[] = []
    Object.entries(replacements).forEach(([key, value]) => {
        const indexes = getAllIndexes(translationKey, `{{${key}}}`)
        indexes.forEach(index =>
            indexForValue.push({
                index,
                value,
                key
            })
        )
    })
    indexForValue = indexForValue.sort((a, b) => a.index - b.index)

    const entries: (string | React.ReactNode)[] = []

    let lastIndex = 0
    indexForValue.forEach(({ index, value, key }) => {
        entries.push(translationKey.substring(lastIndex, index), value)
        lastIndex = index + `{{${key}}}`.length
    })

    if (translationKey.length > lastIndex)
        entries.push(translationKey.substring(lastIndex))

    return entries
}
