import { createContext, useContext } from "react"

import { IArticle } from "data/contentData/interfaces/mongodb/IArticle"
import { IPage } from "data/contentData/interfaces/mongodb/IPage"
import { IReference } from "data/contentData/interfaces/mongodb/IReference"
import getValueFromObject from "lib/getValueFromObject"

const ReferencesContext = createContext<IReference[]>([])

export function ReferencesProvider({
    page,
    children
}: {
    page: IPage
    children: JSX.Element | JSX.Element[]
}) {
    const references = getValueFromObject(page as IArticle, "references") ?? []

    return (
        <ReferencesContext.Provider value={references}>
            {children}
        </ReferencesContext.Provider>
    )
}

/**
 * throws an error if the ReferencesContext can not be found
 * @returns error or references context
 */

export default function useReferences() {
    const context = useContext(ReferencesContext)

    if (context === undefined)
        throw new Error(
            "useReferences must be used within a ReferencesProvider"
        )

    return context
}
