import dynamic from "next/dynamic"

import AppBar from "@mui/material/AppBar"
import Container from "@mui/material/Container"
import Toolbar from "@mui/material/Toolbar"
import { styled } from "@mui/material/styles"

import { IMegamenuCategory } from "data/contentData/interfaces/IMegamenuCategory"

import Image from "Components/Image/Image"
import Link from "Components/Link"
import useUserCategory from "Components/UserCategoryModal/useUserCategory"

import HeaderSearch from "./HeaderSearch/HeaderSearch"
import Megamenu from "./Megamenu/Megamenu"

const SubheaderPro = dynamic(() => import("./SubheaderPro"), {
    ssr: false
})

const logoDimensions = {
    public: {
        sm: {
            height: 25,
            width: 118
        },
        md: {
            height: 39,
            width: 185
        }
    },
    pro: {
        sm: {
            height: 37,
            width: 127
        },
        md: {
            height: 55,
            width: 189
        }
    }
}

export default function Header({
    megamenu
}: {
    megamenu: IMegamenuCategory[]
}) {
    const { userCategory } = useUserCategory()
    const logoPro = `/${process.env.NEXT_PUBLIC_ID}/logo_pro_white.svg`
    const logoPublic = `/${process.env.NEXT_PUBLIC_ID}/logo_white.svg`

    return (
        <>
            <SubheaderPro />
            <AppBar
                position="sticky"
                elevation={0}>
                <Container maxWidth="lg">
                    <StyledToolbar disableGutters>
                        <StyledLink href="/">
                            {userCategory === "professional" ? (
                                <ProLogoImg
                                    src={logoPro}
                                    alt="NHI Pro"
                                    fill
                                />
                            ) : (
                                <LogoImg
                                    src={logoPublic}
                                    alt="NHI.no"
                                    fill
                                />
                            )}
                        </StyledLink>

                        <HeaderItems>
                            <HeaderSearch />
                            <Megamenu data={megamenu} />
                        </HeaderItems>
                    </StyledToolbar>
                </Container>
            </AppBar>
        </>
    )
}

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(2),
    height: theme.spacing(6.75),
    [theme.breakpoints.up("sm")]: {
        height: theme.spacing(10),
        margin: theme.spacing(0, 0.5)
    }
}))

const StyledLink = styled(Link)(() => ({
    display: "flex"
}))

const ProLogoImg = styled(Image)(({ theme }) => ({
    height: logoDimensions.pro.sm.height,
    width: logoDimensions.pro.sm.width,
    marginTop: theme.spacing(0.5),
    [theme.breakpoints.up("sm")]: {
        height: logoDimensions.pro.md.height,
        width: logoDimensions.pro.md.width
    }
}))

const LogoImg = styled(Image)(({ theme }) => ({
    width: logoDimensions.public.sm.width,
    height: logoDimensions.public.sm.height,
    [theme.breakpoints.up("sm")]: {
        width: logoDimensions.public.md.width,
        height: logoDimensions.public.md.height
    }
}))

const HeaderItems = styled("div")(({ theme }) => ({
    display: "flex",
    flexWrap: "nowrap",
    flexGrow: 1,
    justifyContent: "flex-end",
    alignItems: "center",
    gap: theme.spacing(2)
}))
