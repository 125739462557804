import { PortableTextTypeComponentProps } from "@portabletext/react"
import { useState } from "react"

import Popover from "@mui/material/Popover"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import useReferences from "Components/Layout/PageComponent/ReferencesProvider"
import Link from "Components/Link"

interface IReferencesProps {
    ids: number[]
}

export default function InlineReference({
    value
}: PortableTextTypeComponentProps<IReferencesProps>) {
    const { ids } = value

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const open = Boolean(anchorEl)
    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => setAnchorEl(null)

    const referenceIds = ids.map(id => `reference-${id}`)
    const articleReferences = useReferences()
    const references = articleReferences.filter(r =>
        referenceIds.includes(r.referenceId)
    )

    const referenceText = getReferenceText(ids)

    return (
        <>
            <StyledSup onClick={handleOpen}>{referenceText}</StyledSup>
            <Popover
                anchorEl={anchorEl}
                anchorOrigin={{ horizontal: "right", vertical: "center" }}
                transformOrigin={{
                    horizontal: "left",
                    vertical: "center"
                }}
                open={open}
                onClose={handleClose}>
                {references.map(reference => (
                    <StyledReferencesWrapper key={reference.referenceId}>
                        <StyledSupText variant="body2">
                            {reference.text}
                        </StyledSupText>
                        {reference.link && (
                            <Link
                                href={reference.link.href}
                                title={reference.link.title}>
                                {reference.link.text}
                            </Link>
                        )}
                    </StyledReferencesWrapper>
                ))}
            </Popover>
        </>
    )
}

function getReferenceText(ids: number[]) {
    function isOneIncreasingSequence(numbers: number[]) {
        for (let i = 1; i < numbers.length; i++)
            if (numbers[i] !== numbers[i - 1] + 1) return false

        return true
    }

    if (ids.length === 1) return ids[0]
    else if (isOneIncreasingSequence(ids))
        return `${ids[0]}-${ids[ids.length - 1]}`

    return ids.join(", ")
}

const StyledSup = styled("sup")(({ theme }) => ({
    border: "2px solid transparent",
    boxSizing: "border-box",
    color: theme.palette.primary.link,
    margin: theme.spacing(-1, -0.5, -0.5, -0.5),
    padding: theme.spacing(0.5),
    whiteSpace: "nowrap",
    cursor: "pointer",
    "&:hover, &:focus": {
        outline: `3px solid ${theme.palette.primary.dark}`,
        background: "white",
        fontWeight: 600
    }
}))

const StyledSupText = styled(Typography)(() => ({
    wordBreak: "break-word"
}))

const StyledReferencesWrapper = styled("div")(({ theme }) => ({
    maxWidth: 320,
    padding: theme.spacing(2)
}))
