// eslint-disable-next-line no-restricted-imports
import { BareFetcher, Key } from "swr"
// eslint-disable-next-line no-restricted-imports
import useSWRImmutable from "swr/immutable"

function useQueryData<T>(key: Key, fetcher: BareFetcher<T>) {
    const { data, isLoading, error, mutate } = useSWRImmutable<T>(key, fetcher)

    return { data, isLoading, error, mutate }
}

export default useQueryData
