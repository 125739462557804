/* eslint-disable jsx-a11y/alt-text */
import { PortableTextTypeComponentProps } from "@portabletext/react"

import { styled } from "@mui/material/styles"

import Image from "./GalleryImage"

interface IImageListImageProps {
    alt: string
    src: string
    title: string
    index: number
    width: number
    height: number
}
interface IImageListProps {
    images: IImageListImageProps[]
}

export default function ImageList({
    value
}: PortableTextTypeComponentProps<IImageListProps>) {
    const { images } = value

    return (
        <StyledDiv>
            {images.map(image => (
                <Image
                    index={image.index}
                    key={image.index}
                    isInline={false}
                    renderNode={() => void 0}
                    value={{
                        src: image.src,
                        title: image.title,
                        width: image.width,
                        height: image.height,
                        alt: image.title,
                        index: image.index
                    }}
                />
            ))}
        </StyledDiv>
    )
}

const StyledDiv = styled("div")(() => ({
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(160px, 1fr))",
    alignItems: "start"
}))
