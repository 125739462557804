import { styled } from "@mui/material/styles"
import Ad from "./Ad"

export type AdInstreamType =
    | "artikkel_instream1" // article: første reklame
    | "artikkel_instream2" // article: andre reklame

export default function AdInstream({ type }: { type: AdInstreamType }) {

    return (
        <AdWrapper>
            <Ad
                type={type}
                collapseEmpty={""}
            />
        </AdWrapper>
    )
}

const AdWrapper = styled("div")(({ theme }) => ({
    marginBottom: theme.spacing(2)
}))
